<template>
  <b-container fluid class="bg-info text-white py-3 fixed-bottom">
    <p class="mb-0 text-center">&copy; 2024 All Rights Reserved</p>
  </b-container>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
body {
  padding-bottom: 3rem;
}
</style>
